import { AccredibleCookiesBannerComponentModule } from '@accredible-frontend-v2/components/cookies-banner';
import { AccredibleIframeNavigationComponentModule } from '@accredible-frontend-v2/components/iframe-navigation';
import { environment } from '@accredible-frontend-v2/envs';
import { AccredibleAuthenticationServiceModule } from '@accredible-frontend-v2/services/authentication';
import { AccredibleLanguageServiceModule } from '@accredible-frontend-v2/services/language';
import { ThemeGeneratorModule } from '@accredible-frontend-v2/services/theme-generator';
import { AccredibleToastModule } from '@accredible-frontend-v2/toast';
import { HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { GoogleMapsModule } from '@angular/google-maps';
import { MatSidenavModule } from '@angular/material/sidenav';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { SpotlightLoadModule } from './spotlight-load.module';
import { SpotlightRoutingModule } from './spotlight-routing.module';
import { SpotlightContainer } from './spotlight.container';
import { DirectoryEffects } from './stores/directory/directory.effects';
import { DirectoryApiService } from './stores/directory/directory.service';
import { metaReducers, reducers } from './stores/reducer';

const CORE_MODULES = [SpotlightLoadModule, SpotlightRoutingModule, ThemeGeneratorModule];

const CORE_ACCREDIBLE_LIBS_MODULES = [
  AccredibleIframeNavigationComponentModule,
  AccredibleCookiesBannerComponentModule,
  AccredibleAuthenticationServiceModule.forRoot({ app: 'sl' }),
  AccredibleLanguageServiceModule.forRoot(
    ['en'],
    // FIXME(Matt): Re-enable translations once Spotlight has been translated
    // accredibleCustomThemesMetadata[ThemeHelper.getTheme()].languages,
  ),
  AccredibleToastModule.forRoot({
    // The toast durations are 10s/0s due to accessibility considerations,
    // some visual accessible users need more time to see the message
    duration: 10000,
    errorDuration: 0,
  }),
];

const MATERIAL_MODULES = [MatSidenavModule];

const CORE_STORE_EFFECTS = [DirectoryEffects];

const CORE_STORE_SERVICES = [DirectoryApiService];

@NgModule({
  declarations: [SpotlightContainer],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    HttpClientJsonpModule,

    ...CORE_MODULES,
    ...CORE_ACCREDIBLE_LIBS_MODULES,
    ...MATERIAL_MODULES,

    // NGRX
    StoreModule.forRoot(reducers, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
      },
    }),
    GoogleMapsModule,
    environment.type !== 'production'
      ? StoreDevtoolsModule.instrument({ connectInZone: true })
      : [],
    EffectsModule.forRoot(CORE_STORE_EFFECTS),
  ],
  providers: [...CORE_STORE_SERVICES],
  bootstrap: [SpotlightContainer],
})
export class SpotlightModule {}
